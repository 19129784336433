var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "create-support-modal",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.id ? _vm.$t('supportReq.form.edit') : _vm.$t('supportReq.form.title')
    },
    on: {
      "show": function show() {
        if (!_vm.booking && !_vm.id) {
          _vm.bookingInfo = null;
          _vm.onLoadHandle();
        } else if (_vm.id) {
          _vm.onLoadHandle(_vm.id);
        }
      },
      "close": function close() {
        if (!_vm.booking && !_vm.id) {
          _vm.bookingInfo = null;
          _vm.onLoadHandle();
        }
      }
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('supportReq.form.pnr'),
      "vid": "pnr",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "pnr"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('supportReq.form.pnr')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "pnr",
            "name": _vm.$t('supportReq.form.pnr'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('supportReq.form.ph.pnr'),
            "disabled": _vm.isRoleF1 || _vm.booking != null,
            "maxlength": "6",
            "debounce": "300"
          },
          on: {
            "input": function input(val) {
              return _vm.handleDebounceBookingCode(val);
            }
          },
          model: {
            value: _vm.createReq.pnr,
            callback: function callback($$v) {
              _vm.$set(_vm.createReq, "pnr", $$v);
            },
            expression: "createReq.pnr"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _vm.bookingInfo ? _c('b-col', _vm._l(_vm.resolveBookingRequest(_vm.bookingInfo.bookingRequest.itineraries), function (trip, index) {
    return _c('div', {
      key: index,
      staticClass: "text-nowrap text-body-3"
    }, _vm._l(trip.segments, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment,
        staticClass: "d-flex flex-nowrap my-50"
      }, [segment.airline ? _c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "size": "xs",
          "rounded": "",
          "custom-class": "mr-50"
        }
      }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(segment.devSegmentIndex) + " " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber))) + " " + _vm._s(segment.bookingClass) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).dayMonth) + " " + _vm._s("".concat(segment.departure).concat(segment.arrival)) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrivalTime).hourMin) + " ")])], 1);
    }), 0);
  }), 0) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('supportReq.form.content'),
      "vid": "content",
      "rules": _vm.isRoleF2 ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "content"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('supportReq.form.content')) + " "), _vm.isRoleF2 ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "content",
            "placeholder": _vm.$t('supportReq.form.ph.content'),
            "name": _vm.$t('supportReq.form.content'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "disabled": _vm.isRoleF1,
            "rows": "5"
          },
          model: {
            value: _vm.createReq.content,
            callback: function callback($$v) {
              _vm.$set(_vm.createReq, "content", $$v);
            },
            expression: "createReq.content"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _vm.createReq.id != null ? _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('supportReq.form.note'),
      "vid": "note",
      "rules": _vm.isRoleF1 ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "note"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('supportReq.form.note')) + " "), _vm.isRoleF1 ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "note",
            "placeholder": _vm.$t('supportReq.form.ph.note'),
            "name": _vm.$t('supportReq.form.note'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "disabled": _vm.isRoleF2,
            "rows": "5"
          },
          model: {
            value: _vm.createReq.note,
            callback: function callback($$v) {
              _vm.$set(_vm.createReq, "note", $$v);
            },
            expression: "createReq.note"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 1010103536)
  })], 1) : _vm._e(), _vm.createReq.id != null && _vm.isRoleF1 ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('supportReq.form.status'),
      "vid": "status",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "status",
            "state": _vm.getValidationState(validationContext)
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('supportReq.form.status')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "status",
            "options": _vm.status,
            "clearable": false,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('supportReq.form.ph.status')
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("supportReq.status.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("supportReq.status.".concat(data.label))) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.createReq.status,
            callback: function callback($$v) {
              _vm.$set(_vm.createReq, "status", $$v);
            },
            expression: "createReq.status"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.getValidationState(validationContext)
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 4171242084)
  })], 1) : _vm._e(), _vm.isRoleF2 ? _c('b-col', {
    staticClass: "text-danger",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('supportReq.note.title')) + ":")]), _c('ul', {
    staticClass: "dot"
  }, [_c('li', [_vm._v(_vm._s(_vm.$t('supportReq.note.note1')))]), _c('li', [_vm._v(_vm._s(_vm.$t('supportReq.note.note2')))]), _c('li', [_vm._v(_vm._s(_vm.$t('supportReq.note.note3')))])])]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "d-flex-center gap-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click() {
        if (!_vm.booking && !_vm.id) {
          _vm.bookingInfo = null;
          _vm.onLoadHandle();
        }
        _vm.$bvModal.hide('create-support-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }